import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Head from "../components/head";
import BackgroundImage from "gatsby-background-image";
import "styles/projects.scss";
import Fade from "react-reveal/Fade";
import Layout from "../components/layout";
import { projectsList } from "../data/projectPage";
import GitIcon from "../components/gitIcon";

const ProjectsContainer = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allFile(
        sort: { fields: name, order: ASC }
        filter: { sourceInstanceName: { eq: "projects" } }
      ) {
        edges {
          node {
            name
            publicURL
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Head title="Projects" />
      <div className="projects-layout">
        <Fade bottom distance="0.2em">
          <div className="projects-each">
            {data.allFile.edges.map((edge, index) => {
              const { name, hostedLink, gitLink } = projectsList[index];
              return (
                <BackgroundImage
                  key={name}
                  className="projects-each-card"
                  fluid={edge.node.childImageSharp.fluid}
                  style={{
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  <a
                    href={hostedLink}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    <div className="projects-each-card-content">
                      <h3
                        style={{ margin: 0 }}
                        className="projects-each-card-title"
                      >
                        {name}
                      </h3>
                    </div>
                  </a>
                  <div>
                    {gitLink && (
                      <a href={gitLink} className="git-icon">
                        <GitIcon width="32" height="32" />
                      </a>
                    )}
                  </div>
                </BackgroundImage>
              );
            })}
          </div>
        </Fade>
      </div>
    </Layout>
  );
};

export default ProjectsContainer;
