const featuredProjects = [
  {
    name: "Arena-2.0",
    hostedLink: "https://arena-2.herokuapp.com/",
    gitLink: "https://github.com/greykoalacode/Arena-2.0",
    imgUrl:
      "https://res.cloudinary.com/dh6iqxujx/image/upload/v1593789967/Arena/arena2_okz2sg.png",
  },
  {
    name: "RecogNoice",
    hostedLink: "https://recognoice.web.app/",
    gitLink: "https://github.com/greykoalacode/recogNoice",
    imgUrl:
      "https://res.cloudinary.com/dh6iqxujx/image/upload/v1593874500/Arena/recognoice_y5yawi.png",
  },
  {
    name: "HackerNewsGrid",
    hostedLink: "https://newspaperlayout-hn.web.app/",
    gitLink: "",
    imgUrl:
      "https://res.cloudinary.com/dh6iqxujx/image/upload/v1593789641/Arena/hn_itsgd4.png",
  },
];

const projectsList = [
  {
    name: "Arena-2.0",
    hostedLink: "https://arena-2.herokuapp.com/",
    gitLink: "https://github.com/greykoalacode/Arena-2.0",
    imgUrl:
      "https://res.cloudinary.com/dh6iqxujx/image/upload/v1593789967/Arena/arena2_okz2sg.png",
  },
  {
    name: "Gradient Generator",
    hostedLink: "https://greykoalacode.github.io/background-generator/",
    gitLink: "https://github.com/greykoalacode/background-generator",
    imgUrl:
      "https://res.cloudinary.com/dh6iqxujx/image/upload/v1593789550/Arena/grad_fd4m3x.png",
  },
  {
    name: "HackerNews Grid",
    hostedLink: "https://newspaperlayout-hn.web.app/",
    gitLink: "",
    imgUrl:
      "https://res.cloudinary.com/dh6iqxujx/image/upload/v1593789641/Arena/hn_itsgd4.png",
  },
  {
    name: "Product Landing",
    hostedLink: "https://greykoalacode.github.io/product-landing/",
    gitLink: "https://github.com/greykoalacode/product-landing",
    imgUrl:
      "https://res.cloudinary.com/dh6iqxujx/image/upload/v1593789551/Arena/prod_jvyypl.png",
  },
  {
    name: "RecogNoice",
    hostedLink: "https://recognoice.web.app/",
    gitLink: "https://github.com/greykoalacode/recogNoice",
  },
  {
    name: "RoboFriends",
    hostedLink: "https://greykoalacode.github.io/robofriends/",
    gitLink: "https://github.com/greykoalacode/robofriends",
    imgUrl:
      "https://res.cloudinary.com/dh6iqxujx/image/upload/v1593789553/Arena/robo_s0b6bh.png",
  },
  {
    name: "Technical Documentation",
    hostedLink: "https://greykoalacode.github.io/technical-documentation/",
    gitLink: "https://github.com/greykoalacode/technical-documentation",
    imgUrl:
      "https://res.cloudinary.com/dh6iqxujx/image/upload/v1593789556/Arena/techdoc_bogxl3.png",
  },
  {
    name: "Tribute Page",
    hostedLink: "https://greykoalacode.github.io/tribute/",
    gitLink: "https://github.com/greykoalacode/tribute",
    imgUrl:
      "https://res.cloudinary.com/dh6iqxujx/image/upload/v1593789558/Arena/tribut_mrprcz.png",
  },
  {
    name: "Virtual Police",
    hostedLink: "https://greykoalacode.github.io/virtpol/",
    gitLink: "https://github.com/greykoalacode/virtpol",
    imgUrl:
      "https://res.cloudinary.com/dh6iqxujx/image/upload/v1593789560/Arena/virtpol_linaff.png",
  },
];

module.exports = { projectsList, featuredProjects };
